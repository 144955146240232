<template>
<div class="bg--login">
  <header-block />
  <div class="container">
    <div class="login">
      <h1 class="login__tit">
        Вход/Авторизация
      </h1>
      <p class="login__txt">Введите ваш email и пароль для входа</p>
      <form class="form" @submit.prevent="login">
        <div class="form-groupe">
          <label for="email" class="form-groupe__label">
            Email
          </label>
          <input type="email" required v-model="user.email" id="email" class="form-groupe__input">
        </div>
        <div class="form-groupe">
          <label for="pass" class="form-groupe__label">
            Пароль
          </label>
          <input type="password" v-model="user.password"
            required id="pass" class="form-groupe__input">
        </div>
        <div class="error">{{error}}</div>
        <div class="button__block">
          <button class="button" :disabled="loading">
            {{ loading ? 'Загрузка...' : 'Войти' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>
<script>
import HeaderBlock from '@/components/Header.vue';

export default {
  components: {
    HeaderBlock,
  },
  data() {
    return {
      isPassword: true,
      user: {
        email: null,
        password: null,
      },
      error: null,
      loading: false,
    };
  },
  mounted() {
    if (this.$route.query.email) {
      this.user.email = this.$route.query.email;
      this.user.password = this.$route.query.password;
      this.login();
    }
  },
  methods: {
    async login() {
      this.loading = true;
      this.error = '';
      this.user.guide = 6;
      const response = await this.$request.post('/api/guide/login', this.user);
      if (response.status !== 200 || !response.data.success) {
        this.error = 'Неправильный email или пароль';
      } else {
        localStorage.setItem('token', response.data.result.token);
        this.$router.replace('/');
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.bg--main {
  height: 100vh;
  overflow: auto;
}
.error {
  color: #f00;
  font-size: 1.6rem;
  margin-top: -2rem;
  display: block;
}
</style>
